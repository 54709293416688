<template>
  <div class="display-card-box">
    <div class="display-card-header">
      <div
        class="status-badge"
        :style="{ color: color['text'], background: color['bg'] }"
      >
        {{ statusStr }}
      </div>
    </div>
    <div class="display-card-content">
      <el-row>
        <el-col
          v-for="(item, index) of descMetaData"
          :key="index"
          :span="item.span || 12"
        >
          <DescItem
            :label="item.label"
            :link="data.link"
            :line-count="item.lineCount"
          >
            {{
              item.compute
                ? item.compute(data[item.key], data)
                : data[item.key] | textFilter
            }}
          </DescItem>
        </el-col>
      </el-row>
    </div>
    <div class="display-card-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import DescItem from "@/components/Descriptions/Item"
import Icon from "@/components/Icon"

import { TYPE } from "../constants"

import { mapGetters } from "vuex"

export default {
  name: "DisplayCard",
  components: {
    DescItem,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    showActions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["areaTree", "areaMap", "dictionaries"]),
    color() {
      const done = { bg: "rgba(81, 197, 24, 0.07)", text: "rgba(81, 197, 24, 1)" }
      const notDone = { bg: "rgba(245, 34, 45, 0.07)", text: "rgba(245, 34, 45, 1)" }
      return this.data.status ? done : notDone
    },
    statusStr() {
      return this.data.status ? this.$t('503a304') : this.$t('35a2e8d')
    },
    descMetaData() {
      return [
        { label: this.$t('524b5c4'), key: "title", span: 24 },
        { label: this.$t('75cc586'), key: "type", span: 12, compute: (value) => TYPE[value] },
        { label: this.$t('c6598d7'), key: "createTime", span: 12 },
        { label: this.$t('6e491c1'), key: "content", span: 24 },
      ]
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.display-card-box {
  border: 1px solid #e7edef;
  border-radius: 4px;
  padding: 20px 0;
  margin-bottom: 20px;
}

.display-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
}

.status-badge {
  width: 114px;
  height: 36px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  border-radius: 0 18px 18px 0;
}

.display-card-content {
  padding: 20px 30px;
}

.icon.delete-icon {
  background: url("../../../../assets/common/delete.png");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}
.icon.edit-icon {
  background: url("../../../../assets/common/edit.png");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}
.icon {
  margin: 5px;
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.display-card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.action-button {
  color: rgba(0, 164, 255, 1);
  font-size: 14px;
  cursor: pointer;
}
</style>
